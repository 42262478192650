/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Radio } from "@mui/material";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeResetPasswordModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { Form, Formik, FormikHelpers } from "formik";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import * as Yup from "yup";
import CustomCheckbox from "view/components/CheckBox";
import Button from "view/components/Button";
import FormInput from "view/pages/Auth/Components/Inputs/formInput";
// import { resetPasswordValidationSchema } from "utils/validationSchemas";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { setLoadingProgress } from "store/loadingBar";
import MultiSelect from "view/components/Multiselect";
import { getRandomProgress } from "utils/getRandomProgress";
// import { MaterialUiSelect } from "./Select";
import { useParams } from "react-router-dom";
import Roles from "hooks/roles";
import { MaterialUiSelect } from "view/components/Select";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "12px",
  backgroundColor: "#ffff",
};
interface FormValues {
  password: string;
  forceToUpdatePassword: boolean;
  apps: { value: string; label: string };
}

export const ResetPasswordModal = () => {
  const { resetPasswordModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { isMyProfile } = Roles();

  const { programId, organizationsId } = useParams();
  const dispatch = useDispatch();
  const [passwordAutoGenerate, setPasswordAutoGenerate] = useState(true);
  const [usedAppsValidation, setUsedAppsValidation] = useState<any>();
  const handleClose = () => {
    dispatch(closeResetPasswordModalAction());
    setPasswordAutoGenerate(true);
  };
  const [initialValues, setInitialState] = useState<any>({
    password: "",
    forceToUpdatePassword: true,
    apps: { value: "", label: "" },
  });

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>
  ) => {
    if (!passwordAutoGenerate && values.password === "") {
      setFieldError("password", "Please enter a valid password");
      return;
    }

    if (resetPasswordModal.data?.contactPreference === null) {
      const message = isMyProfile(resetPasswordModal.data.personId)
        ? "Please set your contact preference before attempting to change your password."
        : "The user must set their contact preference before they can change their password.";
      Toasts.error(message);
      return;
    }

    
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const res = await apiLibrary.Auth.resetPassword({
        forceToUpdatePassword: values.forceToUpdatePassword,
        password: values.password === "" ? null : values.password,
        // phoneOrEmail: getResetPasswordContactInfo(resetPasswordModal.data),
        phoneOrEmail:
          resetPasswordModal.data?.contactPreference === "email"
            ? resetPasswordModal.data?.email
            : resetPasswordModal.data?.phoneNumber,
        appName: values?.apps?.value,
      });
      Toasts.success(res.message);
      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const isAdmin = resetPasswordModal?.data?.role?.includes("admin");
  const basePasswordValidation = Yup.string().min(
    8,
    "Password must be at least 8 characters long"
  );
  const adminPasswordValidation = Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~]/,
      "Password must contain at least one special character"
    );

  const resetPasswordValidationSchema = Yup.object().shape({
    password:
      usedAppsValidation?.value === "isn_admin"
        ? adminPasswordValidation
        : basePasswordValidation,
    apps: Yup.object().shape({
      value: Yup.string().required("App Is Required"),
      label: Yup.string().required("App Is Required"),
    }),
  });

  return (
    <Modal
      open={resetPasswordModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <Form>
                <div className="flex flex-col justify-start items-start w-[700px] rounded-lg">
                  <div
                    className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                    style={{
                      boxShadow:
                        "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                    }}
                  >
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                        <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid break-all dark:text-textMain">
                          Reset password for {" "}
                          {`${resetPasswordModal?.data?.firstName} ${resetPasswordModal?.data?.lastName}`}
                        </p>
                      </div>
                      <button onClick={handleClose} type="button">
                        <XCloseIcon
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                        />
                      </button>
                    </div>
                    <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-2 ">
                      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                        <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative  rounded-[100px]">
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "#024C6F" },
                                "&:hover": { backgroundColor: "transparent" },
                                "&:focus": {
                                  backgroundColor: "none",
                                  boxShadow: "none",
                                },
                                margin: 0, // Set the margin to 0
                                padding: 0,
                              }}
                              checked={passwordAutoGenerate}
                              onChange={() => {
                                resetForm();
                                setPasswordAutoGenerate(
                                  (prevState) => !prevState
                                );
                              }}
                              disableRipple
                            />
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start flex-grow gap-2">
                          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5 pl-3 pt-1">
                            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
                              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[556px] text-base font-semibold text-left text-textMid dark:text-textMain">
                                Automatically generate a password
                              </p>
                              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[556px] text-sm text-left text-secondaryMidLight dark:text-caption">
                                You'll be able to view and copy the password in
                                the next step
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="relative w-full">
                        <div className="flex items-start justify-start w-full">
                          <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative  rounded-[100px] mt-[4px]">
                              <Radio
                                sx={{
                                  "&.Mui-checked": { color: "#024C6F" },
                                  "&:hover": { backgroundColor: "transparent" },
                                  "&:focus": {
                                    backgroundColor: "none",
                                    boxShadow: "none",
                                  },
                                  margin: 0,
                                  padding: 0,
                                }}
                                checked={!passwordAutoGenerate}
                                onChange={() => {
                                  setFieldValue("forceToUpdatePassword", false);
                                  setPasswordAutoGenerate(
                                    (prevState) => !prevState
                                  );
                                }}
                                disableRipple
                              />
                            </div>
                          </div>
                          <div className="flex flex-col items-start justify-start flex-grow ">
                            <div className="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2.5 pl-3 pt-1">
                              <p className="self-stretch flex-grow-0 flex-shrink-0 w-[268px] text-base font-semibold text-left text-textMid dark:text-textMain">
                                Create password
                              </p>
                              {!passwordAutoGenerate && (
                                <div className="w-full">
                                  <FormInput
                                    disabled={isSubmitting}
                                    label="New password"
                                    type="password"
                                    name="password"
                                    id="EmailOrPhone"
                                    placeholder=""
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    error={errors.password}
                                    touched={touched.password}
                                  />
                                  <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
                                    <p className="flex-grow w-[268px] text-xs text-left text-secondaryMidLight dark:text-caption">
                                      Use 8 or more characters
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[2px] mt-1 w-full px-2">
                      <p
                        className={`flex-grow pb-1 text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
                          touched?.apps && errors?.apps
                            ? "text-accent_1Dark dark:text-accent_1Dark"
                            : ""
                        }`}
                      >
                        {""}
                      </p>
                      <MaterialUiSelect
                        options={resetPasswordModal.data.usedApps.map(
                          (item: any) => ({
                            value: item.id,
                            label: item.title,
                          })
                        )}
                        placeholder={"Select An App"}
                        labelKey="label"
                        valueKey="value"
                        handleChange={(item: any) => {
                          setFieldValue("apps", item);
                          setUsedAppsValidation(item);
                        }}
                        initialValue={values?.apps.value}
                        error={errors?.apps?.label}
                      />
                    </div>
                    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 rounded-lg">
                      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-[3px] rounded-[100px]">
                          <CustomCheckbox
                            onChange={(event: any) =>
                              setFieldValue(
                                "forceToUpdatePassword",
                                event.target.checked
                              )
                            }
                            disabled={passwordAutoGenerate}
                            checked={values.forceToUpdatePassword}
                            label={
                              <div className="flex items-center justify-start flex-grow pl-2">
                                <div className="flex justify-start items-center flex-grow relative py-0.5">
                                  <p className="flex-grow w-[576px] text-[15px] text-left text-textMid dark:text-caption">
                                    Ask user to change their password when they
                                    sign in
                                  </p>
                                </div>
                              </div>
                            }
                            labelPlacement="end"
                            sx={{
                              "&.Mui-checked": { color: "#024C6F" },
                              "&:hover": { backgroundColor: "transparent" },
                              "&:focus": {
                                backgroundColor: "none",
                                boxShadow: "none",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                      <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                        <Button
                          type="button"
                          text="Cancel"
                          filledColor="primary"
                          outlinedColor="primary"
                          textColor="textWhite"
                          className="w-24 h-11"
                          width="35"
                          height="13"
                          fontStyle="font-semibold"
                          variant="outlined"
                          onClick={handleClose}
                        />

                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          text="Create"
                          filledColor="primary"
                          outlinedColor="primary"
                          textColor="textWhite"
                          className="w-24 h-11"
                          width="35"
                          height="13"
                          fontStyle="font-semibold"
                          variant="filled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};

function getResetPasswordContactInfo(data: any) {
  if (data?.contactPreference) {
    return data.contactPreference === "email" ? data.email : data.phoneNumber;
  }
  return data?.email || data?.phoneNumber;
}
