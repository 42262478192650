import React, { useEffect } from "react";
import Button from "view/components/Button";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import ellipsize from "ellipsize";
import Status from "../Status";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProfileObservation } from "store/observationProfile/initialState";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  openArchiveOrRestoreObservationModalAction,
  openInviteExternalReviewerQualityAssuranceObservationModalAction,
} from "store/modals";
import { useDispatch } from "react-redux";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";
import { activitiesFilterReset } from "store/filters";
import { fetchObservationProfileAction } from "store/observationProfile/reducer.actions";
import { fetchObservationsActivitiesAction } from "store/observationsActivities";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import useRoles from "hooks/roles";
import DocumentImportIcon from "assets/icons/HeroIcons/DocumentImport";
import { fetchImportInfoAction } from "store/importInfo/reducer.actions";
import usePermissions from "hooks/usePermissions";
import useDocumentTitle from "hooks/useDocumentTitle";
import Head from "view/components/Head";

interface HeaderProps {
  title: any;
  editHandler: any;
  handleMarkAsComplete: any;
  statusTitle: string;
  editMode: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  canEdit?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  editHandler,
  handleMarkAsComplete,
  statusTitle,
  editMode,
  handleSubmit,
  handleCancel,
  canEdit
}) => {



  const { isExternalReviewer, isUserInternalReviewer  } = useRoles();
  const { observations, qa } = usePermissions();
  const location = useLocation();
  const qualityAssurancePath = location.pathname
    .split("/")
    .includes("quality-assurance");
  const { observationId } = useParams();
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );

  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const handleMarkAsInComplete = async () => {
    try {
      const { message } =
        await apiLibrary.QualityAssurance.adminObservationReview(
          observationProfile.id,
          {
            flag: "green",
            status: "incompleted",
          }
        );
      dispatch(activitiesFilterReset());
      dispatch(fetchObservationProfileAction(observationId));
      Toasts.success(message);
    } catch (error: any) {
      const errMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errMsg);
      console.error("Error while marking the section as completed:", error);
    } finally {
      dispatch(fetchObservationsActivitiesAction(observationId));
    }
  };

  const actions = () => {
    return [
      {
        label: "View protocol",
        onClick: () => {
          if (observationProfile?.dynamicForm?.protocol === null) {
            Toasts.info("There's no protocol attached with this observation ");
            return;
          }
          navigate(
            `/protocols/${observationProfile?.dynamicForm?.protocol?.id}/profile`
          );
        },
        divider: false,
        isVisible: true && !observationProfile.isAnonymous,
      },

      {
        label: "Invite external reviewer",
        onClick: () => {
          dispatch(
            openInviteExternalReviewerQualityAssuranceObservationModalAction({
              id: observationProfile.id,
              programId: observationProfile.program.id,
            })
          );
        },
        divider: false,
        isVisible:
          (statusTitle === "incompleted" ||
            statusTitle === "archived" ||
            statusTitle === "request_to_edit" ||
            observationProfile.isAnonymous
            ? false
            : true) && observations.canCreateExternalReviewer,
        //  &&
        // !observationProfile.requireQa,
      },
      {
        label: "Archive observation",
        onClick: () => {
          const data = {
            id: observationProfile.id,
            programId: observationProfile.program.id,
            status: observationProfile?.status,
            path: "profilePage",
            isDirectoryPath: qualityAssurancePath,
            isArchive: true,
          };
          dispatch(openArchiveOrRestoreObservationModalAction(data));
        },

        divider: false,
        isVisible:
          statusTitle !== "archived" &&
          statusTitle !== "incompleted" &&
          observations.canArchiveObservation &&
          observationProfile.program.programStatus !== "completed" && canEdit,
      },
      {
        label: "Mark as incomplete",
        onClick: () => {
          handleMarkAsInComplete();
        },
        divider: false,
        isVisible:
          statusTitle !== "incompleted" &&
          statusTitle !== "archived" &&
          qa.canReview &&
          !observationProfile.requireQa && !observationProfile.isAnonymous && canEdit,
      },
    ];
  };
  useEffect(() => { }, [statusTitle, observationId]);

  const data = {
    status: statusTitle,
    id: observationId,
    path: "profilePage",
    isDirectoryPath: qualityAssurancePath,
  };

  const handleCancelEdit = () => {
    editHandler();
    handleCancel();
  };

  const openImportInfoModal = () => {
    dispatch(fetchImportInfoAction(observationProfile.id));
  };

  return (
    <div className="flex w-full">
      <Head
        title={`${qualityAssurancePath ? "Quality Assurance" : "Observations"} ${title}`}
      />
      <div className="flex items-center flex-1">
        <p className="text-[28px] break-all font-bold text-left text-textMid dark:text-textMain w-auto">
          {ellipsize(title, 170)}
        </p>
        <div className="flex items-center gap-2">
          <Status title={statusTitle} flag={observationProfile?.flag} />
          {observationProfile.imported && observations.canViewImportInfo && (
            <button onClick={openImportInfoModal}>
              <DocumentImportIcon />
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center justify-end gap-3">
        {!isExternalReviewer() ? (
          <>
            {(statusTitle === "archived" || statusTitle === "incompleted") && observations.canArchiveObservation && canEdit ? (
              <>
                <Button
                  type="button"
                  text="Restore"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2 border-2 border-primary"
                  width="60"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={() => {
                    dispatch(openArchiveOrRestoreObservationModalAction(data));
                  }}
                />
                {!observationProfile.isAnonymous && statusTitle === "archived" &&
                  <div>
                    <PopOverMenu icon={<DotsVerticalIcon />}>
                      <div>
                        <ActionDropDownMenu actions={actions()} />
                      </div>
                    </PopOverMenu>
                  </div>
                }
              </>
            ) : (
              <>
                {(observations.canEditObservation && canEdit) && (
                  <Button
                    type="button"
                    text={editMode ? "Save and exit" : "Edit"}
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 dark:hover:border-primary dark:hover:!bg-[transparent] dark:hover:text-primary"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={editMode ? handleSubmit : editHandler}
                  />
                )}
                {(statusTitle !== "completed" && !editMode && qa.canReview && !observationProfile.isAnonymous && canEdit) && (
                  <Button
                    type="button"
                    text="Mark as Complete"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 border-2 border-primary"
                    width="60"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                    disabled={!qa.canReview}
                    onClick={handleMarkAsComplete}
                  />
                )}

                {editMode && (
                  <Button
                    type="button"
                    text="Cancel"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 border-2 border-primary w-[100px]"
                    width="60"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                    onClick={handleCancelEdit}
                  />
                )}
                <div></div>
                <div>
                  <PopOverMenu icon={<DotsVerticalIcon />}>
                    <div>
                      <ActionDropDownMenu actions={actions()} />
                    </div>
                  </PopOverMenu>
                </div>
              </>
            )}
          </>
        ) : (
          isExternalReviewer() && (
            <div>
              <PopOverMenu icon={<DotsVerticalIcon />}>
                <div>
                  <ActionDropDownMenu actions={actions()} />
                </div>
              </PopOverMenu>
            </div>
          )
        )}
      </div>
    </div>
  );
};
