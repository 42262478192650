import {
  Header,
  ObserverTimeZoneAccordionToggle,
} from 'Components/ObservationProfile';
import { Breadcrumb } from 'Components/ObservationProfile/BreadCrumb';
import InfoBar from 'Components/ObservationProfile/InfoBar';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';
import { RootState } from 'store';
import {
  fetchObservationProfileAction,
  resetObservationProfileAction,
} from 'store/observationProfile/reducer.actions';
import { Form, Formik, FormikValues } from 'formik';
import { sentenceCase } from 'change-case';

/** mui accordion */
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CheveronUpIcon from 'assets/icons/HeroIcons/CheveronUpIcon';
import FlagIcon from 'assets/icons/HeroIcons/FlagIcon';
import CheckCircleIcon from 'assets/icons/HeroIcons/CheckCircleIcon';
import ObservationsActivities from '../ObservationActivities';
import { IProfileObservation } from 'store/observationProfile/initialState';
import { cloneDeep, values } from 'lodash';
import RiverCondition from './staticComponents/RiverConditions';
import { panelsName } from './staticComponents/panelsTitle';
import { snakeToCamel } from 'utils/caseConvertor';
import GeneralInfo from './staticComponents/GeneralInfo';
import WeatherCondition from './staticComponents/WeatherCondition';
import Harvest from './staticComponents/Harvest';
import SpeciesDetails from './staticComponents/SpeciesDetails';
import GpsInfo from './staticComponents/GpsInfo';
import SightingDetails from './staticComponents/SightingDetails';
import CountDetails from './staticComponents/CountDetails';
import Sample from './staticComponents/Sample';
import DynamicComponent from './dynamicComponent';
import apiLibrary from 'services/api';
import { Toasts } from 'view/components/Toasts';
import useDateFormatter from 'hooks/formatDateWithPattern';
import { activitiesFilterReset } from 'store/filters';
import { fetchObservationsActivitiesAction } from 'store/observationsActivities';
import apiClient from 'services/apiClient';
import axios from 'axios';
import { baseURL } from 'config';
import useRoles from 'hooks/roles';
import GeographicalInformation from './staticComponents/GeographicalInformation';
import { convertJsonOfGeographicalPanel } from 'view/pages/Forms/EditFormDetails/Components/SurveyJs/dataConverters';
import usePermissions from 'hooks/usePermissions';
import PanelComponent from './panelComponent';
import questionTypes from 'view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes';
import EnvironmentalCondition from './staticComponents/EnvironmentalConditions';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment-timezone';
import { CollapseOptions } from 'Components/ObservationProfile/CollapseOptions';
import { CheveronLeftIcon, CheveronRightIcon } from 'assets/icons/HeroIcons';
import './observation.scss';
// import surveyData from "./data/index.json"

interface Field {
  name: string;
  title: string;
  isRequired: boolean;
  type: 'text' | 'radiogroup' | 'checkbox' | 'tagbox'; // Add more types as needed
  answer: string | number | any[];
}
interface Panel {
  name: string;
  templateElements?: Field[];
  elements?: Field[];
}

interface FileUploadResponse {
  data: {
    id: string;
  };
}

export const ObservationProfile = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const foo = params.get('editMode');
  const { isExternalReviewer } = useRoles();
  const { observationId } = useParams();
  const [observationProfileData, setObservationProfileData] =
    useState<IProfileObservation>();
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );
  const { isUserInternalReviewer, isAdminUser } = useRoles();

  const panels = Object.values(panelsName);
  useEffect(() => {
    if (observationProfile) {
      setObservationProfileData(observationProfile);
    }
  }, [observationProfile]);

  const { qa } = usePermissions();

  let location = useLocation();
  const checkParams = location.pathname.includes('quality-assurance');

  const canEdit = (isUserInternalReviewer() && checkParams) || isAdminUser();

  const { formatDate } = useDateFormatter();
  const { observations } = usePermissions();

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(new Set([0]));
  const [edit, setEdit] = useState<boolean>(false);
  const [closeMessagePanel, setCloseMessagePanel] = useState(false);

  const accordionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  useEffect(() => {
    dispatch(fetchObservationProfileAction(observationId));

    if (location?.state?.editMode) {
      editHandler();
      navigate('.', {
        state: { editMode: true },
        replace: true,
      });
    }
    return () => {
      dispatch(resetObservationProfileAction());
    };
  }, []);

  const dynamicObservationLinks = [
    {
      path: !checkParams ? `/observations/list` : `/quality-assurance/list`,
      label: !checkParams ? `Observations` : `Quality Assurance`,
    },
    {
      path: !checkParams
        ? `/observations/${observationProfile?.id}/profile`
        : `/quality-assurance/${observationProfile?.id}/profile`,
      label: observationProfile?.code,
    },
  ];
  const dynamicObservationExternalReviewerLinks = [
    {
      path: !checkParams ? `/observations/list` : `/quality-assurance/list`,
      label: !checkParams ? `Observations` : `Quality Assurance`,
    },
    {
      path: !checkParams
        ? `/observations/${observationProfile?.id}/profile`
        : `/quality-assurance/${observationProfile?.id}/profile`,
      label: observationProfile?.code,
    },
  ];

  // Toggle panel open/close
  const handleAccordionChange = (index: number) => {
    const newExpanded = new Set(expanded);
    if (newExpanded.has(index)) {
      newExpanded.delete(index);
    } else {
      newExpanded.add(index);
    }
    setExpanded(newExpanded);
  };

  function isVisibleExistInElement(element: any) {
    // Returns the value of `visible` if it exists, otherwise true
    return element.visible !== undefined ? element.visible : true;
  }

  const createFieldSchema = (field: any) => {
    let baseValidator;
    const isRequired = field.isRequired;
    switch (field.type) {
      case 'text':
      case 'radiogroup':
        baseValidator = Yup.mixed();
        break;
      case 'checkbox':
        baseValidator = Yup.mixed();
        break;
      case 'audio':
        baseValidator = Yup.object().shape({
          url: isRequired
            ? Yup.string()
                .url('Must be a valid URL')
                .required(`${field.title || field.name} is required`)
            : Yup.string().url('Must be a valid URL'),
          transcription: Yup.string().max(6000, '6000 max characters'),
        });
        break;
      case 'tagbox':
        baseValidator = Yup.array()
          .of(Yup.mixed())
          .typeError(
            `${field.title || field.name} must be an array type, but the final value was: '${typeof field.answer}'`
          );
        break;
      case 'dropdown':
        baseValidator = Yup.mixed().test(
          'object-or-string',
          `${field.title || field.name} must be an 'object' or 'string' type, but the final value was: '${typeof field.answer}'`,
          (value) => typeof value === 'object' || typeof value === 'string'
        );
        break;

      case questionTypes.shapePicker:
      case questionTypes.pointPicker:
      case questionTypes.drawPolygon:
      case questionTypes.dropPin:
        baseValidator = Yup.mixed().test(
          'object-or-string',
          `${field.title || field.name} must be an 'object' or 'string' type, but the final value was: '${typeof field.answer}'`,
          (value) => typeof value === 'object' || typeof value === 'string'
        );
        break;

      default:
        baseValidator = Yup.mixed();
    }

    // Apply nullable and required based on the field properties
    if (field.isRequired && isVisibleExistInElement(field)) {
      baseValidator = baseValidator.required(
        `${field.title || field.name} is required`
      );
    } else {
      baseValidator = baseValidator.nullable();
    }

    return baseValidator;
  };

  const createPanelSchema = (
    panelFields: Field[],
    panel: any
  ): Yup.ObjectSchema<Record<string, any>> => {
    const schemaFields: { [key: string]: any } = {};
    const isPanelDynamic =
      panel.type === 'paneldynamic' && !panels.includes(panel.name);
    panelFields.forEach((field: any) => {
      const elementName = isPanelDynamic
        ? field.groupQuestionName || (field.name as string)
        : (field.name as string);
      schemaFields[elementName] = createFieldSchema(field);
    });

    return Yup.object().shape(schemaFields);
  };

  const createValidationSchema = (
    panels: any
  ): Yup.ObjectSchema<Record<string, any>> => {
    const schemaPanels: {
      [key: string]: Yup.ObjectSchema<Record<string, any>>;
    } = {};

    panels?.forEach((panel: any) => {
      const panelName = snakeToCamel(panel?.name ?? '') as string;
      const panelElements =
        panel.templateElements && panel.templateElements.length > 0
          ? panel.templateElements
          : panel.elements && panel.elements.length > 0
            ? panel.elements
            : [];

      schemaPanels[panelName] = createPanelSchema(panelElements, panel);
    });

    return Yup.object().shape(schemaPanels);
  };

  const validationSchema = useMemo(() => {
    return createValidationSchema(observationProfileData?.sections);
  }, [observationProfileData]);

  // Generating Formik Initial Values
  const formInitialValues = useMemo(() => {
    const initialValues: { [key: string]: { [key: string]: any } } = {};

    observationProfileData?.sections.forEach((panel) => {
      const panelName = snakeToCamel(panel.name ?? '') as string;
      initialValues[panelName] = {};

      const panelElements =
        panel.templateElements && panel.templateElements.length > 0
          ? panel.templateElements
          : panel.elements && panel.elements.length > 0
            ? panel.elements
            : [];

      const isGpsInfo =
        panels.includes(panel.name) &&
        panel.name === panelsName.GPS_INFORMATION;

      const inSighteningDetails =
        panels.includes(panel.name) &&
        panel.name === panelsName.SIGHTING_DETAIL;

      // check if panel is dynamic
      const isPanelDynamic =
        panel.type === 'paneldynamic' && !panels.includes(panel.name);
      panelElements.forEach((element: any) => {
        const elementName = isPanelDynamic
          ? element.groupQuestionName || (element.name as string)
          : (element.name as string);
        initialValues[panelName][elementName] =
          (isGpsInfo || inSighteningDetails) &&
          elementName === 'CoordinatesFormat'
            ? { text: 'Decimal degrees', value: 'Decimal degrees' }
            : element?.type === 'audio' && typeof element?.answer === 'string'
              ? { url: element?.answer, transcription: '' }
              : element?.answer;
      });
    });

    return initialValues;
  }, [observationProfileData, observationId]);

  function editHandler() {
    setEdit(!edit);
  }

  // Handle Mark Complete Action
  const handleMarkAsComplete = async () => {
    if (observationProfile.status === 'completed') {
      return;
    }
    try {
      const { message } =
        await apiLibrary.QualityAssurance.adminObservationReview(
          observationProfile.id,
          {
            flag: 'green',
            status: 'completed',
          }
        );
      dispatch(activitiesFilterReset());
      dispatch(fetchObservationProfileAction(observationId));
      dispatch(fetchObservationsActivitiesAction(observationId));

      Toasts.success(message);
    } catch (error: any) {
      const errMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errMsg);
      console.error('Error while marking the section as completed:', error);
    } finally {
      dispatch(fetchObservationsActivitiesAction(observationId));
    }
  };

  function getValueOrFallback(element: any) {
    if (element && !Array.isArray(element)) {
      return element;
    }
    if (Array.isArray(element) && element.length > 0) {
      return element;
    }
    return null;
  }

  const handleUpdateStatus = (name: string, sectionId: number) => {
    // Get all panels
    const observationPanels = observationProfileData?.sections;

    if (observationPanels)
      for (const panel of observationPanels) {
        if (panel.sectionId === sectionId) {
          const panelElements = getPanelElements(panel);

          for (const element of panelElements) {
            // Update the element isUpdated key for Dyanmic, static and hardcoded panels
            if (
              (isDynamicPanel(panel)
                ? element.groupQuestionName
                : element.name) === name
            ) {
              element.isUpdated = true;
            }
          }
          // Update the Panel isUpdated key as well
          panel.isUpdated = true;
        }
      }
  };

  // Function to determine if a value is empty
  const isEmpty = (value: any) => {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' &&
        value !== null &&
        Object.keys(value).length === 0)
    );
  };

  // Submit Handler
  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ): Promise<void> => {
    const surveyData = cloneDeep(observationProfileData);
    for (const panel of surveyData?.sections!) {
      const panelElements =
        panel.templateElements && panel.templateElements.length > 0
          ? panel.templateElements
          : panel.elements && panel.elements.length > 0
            ? panel.elements
            : [];

      if (panel.sectionId) {
        panel.section_id = panel.sectionId;
      }

      const panelName = snakeToCamel(panel.name ?? '');

      for (const element of panelElements) {
        // Latitude & Longitude degress, minutes, seconds
        if (
          (panel.name === panelsName.GPS_INFORMATION ||
            panel.name === panelsName.SIGHTING_DETAIL) &&
          (element.name === 'Latitude' || element.name === 'Longitude')
        ) {
          // Check if the value is an object and if it's not empty
          if (
            values[panelName][element.name] &&
            typeof values[panelName][element.name] === 'object'
          ) {
            const valueObject = values[panelName][element.name];
            // Ensure 'showSeconds' and required properties exist before manipulating
            if (valueObject.hasOwnProperty('showSeconds')) {
              if (
                !valueObject.showSeconds &&
                valueObject.hasOwnProperty('seconds')
              ) {
                // Ensure seconds is a float for accurate calculation
                const secondsAsMinutes =
                  parseFloat(valueObject.seconds || 0) / 60;

                if (valueObject.hasOwnProperty('minutes')) {
                  // Ensure minutes is treated as a float to avoid any implicit type coercion issues
                  valueObject.minutes = String(
                    parseFloat(valueObject.minutes) + secondsAsMinutes
                  );
                } else {
                  // Initialize minutes from seconds if minutes are not present
                  valueObject.minutes = String(secondsAsMinutes);
                }
                delete valueObject.seconds;
              } else if (
                valueObject.showSeconds &&
                valueObject.seconds === undefined
              ) {
                return;
              }

              delete valueObject.showSeconds;
            }

            // Use Object.values() to iterate over property values of the object
            if (Object.values(valueObject).some((value) => isEmpty(value))) {
              return; // Exit the function or loop if an empty value is found
            }
          }
        }

        // If element has default value then set default value as answer and return;
        if (element.updatedDefaultValue) {
          element.answer = element.updatedDefaultValue;
        } else if (element.defaultValue) {
          element.answer = element.defaultValue;
        } else {
          const isPanelDynamic =
            panel.type === 'paneldynamic' && !panels.includes(panel.name);
          if (element.type === 'file') {
            const files =
              values[panelName][
                isPanelDynamic
                  ? element.groupQuestionName || element.name
                  : element.name
              ]; // This could be an array of File objects or uploaded file objects
            if (files?.length > 0) {
              const uploadFilePromises = files.map(async (file: any) => {
                if (file instanceof File) {
                  try {
                    const res = await apiLibrary.file.fileUpload(
                      file,
                      false,
                      'public'
                    );
                    // Assuming res.data contains id, type, and path
                    return {
                      id: res.data.id,
                      type: res.data.type,
                      url: res.data.path,
                    };
                  } catch (error) {
                    console.error('Error uploading file:', error);
                    return null;
                  }
                } else {
                  // Assuming file is already an object with id, type, and url
                  return file;
                }
              });

              const uploadedFiles = await Promise.all(uploadFilePromises);
              element.answer = uploadedFiles.filter((file) => file !== null);
            } else {
              element.answer = [];
            }
          } else {
            const isPanelDynamic =
              panel.type === 'paneldynamic' && !panels.includes(panel.name);

            const questionNameType = isPanelDynamic
              ? element.groupQuestionName || element.name
              : element.name;

            // If Audio element then update its keys
            if (element.type === 'audio') {
              const initialAudioUrl =
                formInitialValues[panelName][questionNameType]?.url;
              const currentAudioUrl = values[panelName][questionNameType]?.url;
              const initialTranscription =
                formInitialValues[panelName][questionNameType]?.transcription;
              const currentTranscription =
                values[panelName][questionNameType]?.transcription;

              // Check if the URL has changed
              const urlChanged = initialAudioUrl !== currentAudioUrl;
              // Check if the transcription has changed
              const transcriptionChanged =
                initialTranscription !== currentTranscription;

              // Update the element flags based on the changes
              element.isAudioUpdated = urlChanged;
              element.isTranscriptionUpdated = transcriptionChanged;
            }
            element.answer = values[panelName][questionNameType];
          }
        }
      }
    }

    if (observationId) {
      try {
        const { data, message } =
          await apiLibrary.Observations.updateObservation(
            { id: parseInt(observationId, 10) },
            surveyData
          );
        navigate(
          checkParams ? '/quality-assurance/list' : '/observations/list'
        );
        Toasts.success(message);
      } catch (error: any) {
        const errorMsg =
          error?.response?.data?.message ??
          error?.message ??
          'Error submitting observation';
        Toasts.error(errorMsg);
      } finally {
        setEdit(false); // Assuming setEdit is a state setter to toggle edit mode
        setSubmitting(false);
      }
    }
  };

  // Render Static Components
  const renderStaticComponent = (panel: any, formikProps: any) => {
    let panelName = panel.name;
    // Implement your logic to render static components based on panelName
    switch (panelName) {
      case panelsName.GENERAL_INFORMATION:
        return (
          <GeneralInfo
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );

      case panelsName.RIVER_CONDITION:
        return (
          <RiverCondition
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );

      case panelsName.WEATHER_CONDITION:
        return (
          <WeatherCondition
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );

      case panelsName.HARVEST:
        return (
          <Harvest
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );
      case panelsName.GEOGRAPHICAL_INFORMATION:
        return (
          <GeographicalInformation
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );

      case panelsName.SPECIES_DETAIL:
        return (
          <SpeciesDetails
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );
      case panelsName.GPS_INFORMATION:
        return (
          <GpsInfo
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );
      case panelsName.SIGHTING_DETAIL:
        return (
          <SightingDetails
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );
      case panelsName.COUNT_DETAILS:
        return (
          <CountDetails
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );
      case panelsName.SAMPLE:
        return (
          <Sample
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );

      case panelsName.ENVIRONMENTAL_CONDITIONS:
        return (
          <EnvironmentalCondition
            panel={panel}
            editMode={edit}
            formikProps={formikProps}
            handleUpdateStatus={handleUpdateStatus}
          />
        );

      // Add more cases as needed
      default:
        return <></>;
    }
  };

  // Render Dynamic Component
  const renderDynamicComponent = (panel: any, formikProps: any) => {
    return (
      <DynamicComponent
        {...formikProps}
        panel={panel}
        editMode={edit}
        formikProps={formikProps}
        handleUpdateStatus={handleUpdateStatus}
        setObservationProfileData={setObservationProfileData}
        observationProfileData={observationProfileData}
      />
    );
  };

  // Render Panel Component
  const renderPanelComponent = (panel: any, formikProps: any) => {
    return (
      <PanelComponent
        {...formikProps}
        panel={panel}
        editMode={edit}
        formikProps={formikProps}
        handleUpdateStatus={handleUpdateStatus}
      />
    );
  };

  useEffect(() => {
    // Extract the sectionId and questionName from the URL hash
    const [hashSectionId, query] = location.hash?.replace('#', '').split('?');
    const sectionId = Number(hashSectionId);
    const searchParams = new URLSearchParams(query);
    const questionName = searchParams.get('q');

    if (sectionId) {
      // Check if the ID matches a section first
      const sectionIndex = observationProfile.sections.findIndex(
        (section: any) => section.sectionId === sectionId
      );

      if (sectionIndex >= 0) {
        // Scenario 1: It's a sectionId, scroll to the section and open it
        if (!expanded.has(sectionIndex)) {
          handleAccordionChange(sectionIndex); // Open the accordion section
        }

        setTimeout(() => {
          const ref = accordionRefs.current[sectionIndex];
          if (ref) {
            ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }

          // Scenario 2: If there's a questionName, scroll to the specific question within the section
          if (questionName) {
            const questionRef = document?.querySelector(
              `#${questionName}${sectionId}`
            );
            if (questionRef) {
              questionRef.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }
        }, 300);
      }
    }
  }, [location]);

  // Reverting things back on cancel
  const handleCancelAction = (formikProps: any) => {
    setObservationProfileData(observationProfile);
    formikProps.resetForm();
  };

  function renderPanels(panel: any, formikProps: any) {
    if (
      (panel.type === 'panel' || panel.type === 'paneldynamic') &&
      panels.includes(panel.name)
    ) {
      return renderStaticComponent(panel, formikProps);
    } else if (panel.type === 'paneldynamic') {
      return renderDynamicComponent(panel, formikProps);
    } else if (panel.type === 'panel') {
      return renderPanelComponent(panel, formikProps);
    }
  }

  const handleExpandAll = () => {
    // Extract all sectionIds from the sections array, filter out any undefined values
    const allSectionIndexes = new Set(
      observationProfileData?.sections.map((section, index) => index) // Using the index directly
    );
    // Set the expanded state to include all sectionIds
    setExpanded(allSectionIndexes);
  };

  const handleCollapseAll = () => {
    setExpanded(new Set()); // Pass an empty Set to clear the expansion
  };

  return (
    <div className='w-full pt-4 cursor-default'>
      <Formik
        initialValues={formInitialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          return observationProfile.isLoading ? (
            <div className='min-h-[100vh] flex items-center justify-center h-full loader'>
              <TailSpin
                height='50'
                width='50'
                color='#005C89'
                ariaLabel='tail-spin-loading'
                radius='2'
                wrapperStyle={{}}
                wrapperClass='tailspin-loader'
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className='mb-5 '>
                <Breadcrumb
                  links={
                    isExternalReviewer()
                      ? dynamicObservationExternalReviewerLinks
                      : dynamicObservationLinks
                  }
                />
                <Header
                  title={observationProfile?.code}
                  editHandler={editHandler}
                  handleMarkAsComplete={handleMarkAsComplete}
                  statusTitle={observationProfile?.status}
                  editMode={edit}
                  handleSubmit={formikProps.handleSubmit}
                  handleCancel={() => handleCancelAction(formikProps)}
                  canEdit={canEdit}
                />
              </div>
              {/* Observation Info */}
              <div className='mb-6 observation-info'>
                <InfoBar canEdit={canEdit} />
              </div>
              <div className='relative w-full gap-5 mt-2 mb-16 flex h-[81vh] justify-between'>
                <div
                  className={`${closeMessagePanel || !observations.canViewActivities || observationProfile.isAnonymous ? 'w-full' : ' w-4/6 '} h-full transition-[width] ease-in-out duration-500`}
                >
                  <div className='relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 '>
                    {observationProfile.status === 'request_to_edit' && (
                      <ObservationRequestConsent />
                    )}
                    <div className='flex justify-between items-center w-full pb-3 pr-2'>
                      <ObserverTimeZoneAccordionToggle />
                      <CollapseOptions
                        onExpandAll={handleExpandAll}
                        onCollapseAll={handleCollapseAll}
                      />
                    </div>
                    <div className='h-[75vh] overflow-y-auto pr-1 w-full'>
                      <Form className='w-full'>
                        {observationProfileData?.sections.map(
                          (panel: any, index: number) => {
                            const panelElements =
                              panel.templateElements &&
                              panel.templateElements.length > 0
                                ? panel.templateElements
                                : panel.elements && panel.elements.length > 0
                                  ? panel.elements
                                  : [];

                            // if (panelElements.length > 0) {
                            return (
                              <div
                                key={index}
                                className='w-full mb-2 rounded-lg bg-bgWhite dark:bg-secondaryLight '
                                id={panel?.sectionId}
                                ref={(el) =>
                                  (accordionRefs.current[index] = el)
                                }
                              >
                                <Accordion
                                  key={index}
                                  expanded={expanded.has(index)} // Check if the index is in the set
                                  className='!rounded !shadow-none !px-6 dark:bg-secondaryLight '
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <CheveronUpIcon className='h-4' />
                                    }
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                    className='!px-0'
                                    onClick={(e) =>
                                      handleAccordionChange(index)
                                    }
                                  >
                                    <Typography className='flex justify-between w-full py-1'>
                                      <div>
                                        <h1 className='text-lg font-semibold capitalize font-Overpass dark:text-textMain'>
                                          {panel?.title
                                            ? panel?.title
                                            : sentenceCase(panel?.name)}
                                        </h1>
                                      </div>
                                      {!isExternalReviewer() &&
                                        qa.reviewSection &&
                                        !observationProfile.requireQa &&
                                        !observationProfile.isAnonymous && (
                                          <div className='flex items-center mr-4'>
                                            <div
                                              className='flex items-center gap-4'
                                              onClick={(event) =>
                                                event.stopPropagation()
                                              }
                                            >
                                              <FlagButton
                                                flag={panel.flag}
                                                observationId={
                                                  observationProfile.id
                                                }
                                                sectionId={panel.sectionId}
                                                canEdit={canEdit}
                                              />
                                              <CompleteButton
                                                flag={panel.flag}
                                                observationId={
                                                  observationProfile.id
                                                }
                                                sectionId={panel.sectionId}
                                                canEdit={canEdit}
                                              />
                                            </div>
                                          </div>
                                        )}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails className='!p-0'>
                                    {renderPanels(panel, formikProps)}
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            );
                            // }
                            // return null;
                          }
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
                {observations.canViewActivities &&
                  !observationProfile.isAnonymous && (
                    <div
                      className={` relative transition-[width] ease-in-out duration-500 ${closeMessagePanel ? 'w-2 ' : 'w-4/12'} bg-bgWhite rounded-lg relative h-full`}
                    >
                      <button
                        className=' ChevronRightIconHover'
                        onClick={() => setCloseMessagePanel(!closeMessagePanel)}
                      >
                        {closeMessagePanel ? (
                          <CheveronLeftIcon className='h-4 w-4 hoverEffect transition ease-in' />
                        ) : (
                          <CheveronRightIcon className='h-4 w-4  hoverEffect transition ease-in' />
                        )}
                      </button>
                      {!closeMessagePanel && (
                        <ObservationsActivities
                          handleExpandAllPanels={handleExpandAll}
                          canEdit={canEdit}
                        />
                      )}
                    </div>
                  )}
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ObservationProfile;

interface FlagButtonProps {
  flag: string;
  observationId: number;
  sectionId: number;
  canEdit: boolean;
}

const FlagButton: React.FC<FlagButtonProps> = ({
  flag,
  observationId,
  sectionId,
  canEdit,
}) => {
  const { isExternalReviewer } = useRoles();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { qa } = usePermissions();
  const flagTheSection = async () => {
    if (flag === 'red') {
      return;
    }
    try {
      const { message } =
        await apiLibrary.QualityAssurance.adminObservationSectionReview(
          observationId,
          {
            flag: 'red',
            sectionId: sectionId,
          }
        );
      dispatch(activitiesFilterReset());
      dispatch(fetchObservationProfileAction(observationId));
      Toasts.success(message);
    } catch (error: any) {
      const errMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errMsg);
      console.error('Error while marking the section as completed:', error);
    } finally {
      dispatch(fetchObservationsActivitiesAction(observationId));
    }
  };

  return (
    <button
      className={`py-1 px-1 border flex rounded-lg ${
        flag === 'red'
          ? 'border-accent_1Light bg-accent_1ExtraLight dark:bg-accent_1_14 dark:border-accent_1 '
          : 'border-lineMid'
      }`}
      onClick={flagTheSection}
      type='button'
      disabled={(isExternalReviewer() && qa.reviewSection) || !canEdit}
    >
      <FlagIcon
        fill={flag === 'red' ? '#BF200B' : '#6F747B'}
        className='w-5 h-5'
      />
      <p
        className={`px-2 font-Overpass text-sm ${
          flag === 'red'
            ? 'text-textNormal dark:text-accent_1Dark'
            : 'text-secondaryLight dark:text-caption '
        }`}
      >
        {flag === 'red' ? 'Flagged' : 'Flag'}
      </p>
    </button>
  );
};

interface CompleteButtonProps {
  flag: string;
  observationId: number;
  sectionId: number;
  canEdit: boolean;
}

const CompleteButton: React.FC<CompleteButtonProps> = ({
  flag,
  observationId,
  sectionId,
  canEdit,
}) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { isExternalReviewer } = useRoles();
  const { qa } = usePermissions();
  const markAsCompleteTheSection = async () => {
    if (flag === 'green') {
      return;
    }
    try {
      const { message } =
        await apiLibrary.QualityAssurance.adminObservationSectionReview(
          observationId,
          {
            sectionId: sectionId,
            sectionStatus: 'completed',
          }
        );
      dispatch(activitiesFilterReset());
      dispatch(fetchObservationProfileAction(observationId));
      Toasts.success(message);
    } catch (error: any) {
      const errMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errMsg);
      console.error('Error while marking the section as completed:', error);
    } finally {
      dispatch(fetchObservationsActivitiesAction(observationId));
    }
  };

  return (
    <button
      className={`py-1 px-1 border flex rounded-lg ${
        flag === 'green'
          ? 'border-tertiaryLight bg-tertiaryExtraLight dark:bg-tertiaryMid_14 dark:border-tertiaryMid'
          : 'border-lineMid'
      }`}
      onClick={markAsCompleteTheSection}
      type='button'
      disabled={(isExternalReviewer() && qa.reviewSection) || !canEdit}
    >
      <CheckCircleIcon
        className='w-5 h-5'
        fill={flag === 'green' ? '#009F88' : '#6F747B'}
      />
      <p
        className={`px-2 font-Overpass text-sm ${
          flag === 'green'
            ? 'text-textNormal dark:text-tertiaryMid'
            : 'text-secondaryLight dark:text-caption '
        }`}
      >
        {' '}
        Complete
      </p>
    </button>
  );
};

const ObservationRequestConsent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { observations } = usePermissions();
  const observationProfile = useSelector<RootState, IProfileObservation>(
    (state) => state?.observationProfile
  );
  const { formatDate } = useDateFormatter();

  const rejetOrApproveRequestConsent = async (consent: string) => {
    setIsLoading(true);
    try {
      const { message } =
        await apiLibrary.QualityAssurance.adminObservationRequestConsent(
          observationProfile.id,
          consent
        );
      dispatch(fetchObservationProfileAction(observationProfile.id));
      Toasts.success(message);
    } catch (error: any) {
      const errMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errMsg);
      console.error('Error while marking the section as completed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className='flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-3 px-6 py-3 rounded-lg mb-3 bg-[#fdf2d9]'
      style={{ boxShadow: '0px 1px 0px 0 rgba(2,13,36,0.06)' }}
    >
      <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='relative flex-grow-0 flex-shrink-0 w-6 h-6 mt-[3px]'
        preserveAspectRatio='xMidYMid meet'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11 8C11 7.44772 11.4477 7 12 7H12.01C12.5623 7 13.01 7.44772 13.01 8C13.01 8.55228 12.5623 9 12.01 9H12C11.4477 9 11 8.55228 11 8ZM10 12C10 11.4477 10.4477 11 11 11H12C12.5523 11 13 11.4477 13 12V15C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H12C11.4477 17 11 16.5523 11 16V13C10.4477 13 10 12.5523 10 12Z'
          fill='#F2AA00'
        />
      </svg>
      <div className='flex flex-col  w-[80%] justify-center items-start flex-grow relative py-0.5'>
        <p className='self-stretch flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-secondaryMid'>
          {`Observer requested to edit this observation on
        ${formatDate(observationProfile.editRequestAt)}`}
          .
        </p>
        <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1'>
          <p className='flex-grow-0 flex-shrink-0 text-sm font-bold text-left text-secondaryMid'>
            Reason:
          </p>
          <p className='flex-grow text-sm text-left text-secondary'>
            {observationProfile.reasonToEdit}
          </p>
        </div>
      </div>
      <button
        type='button'
        disabled={isLoading || !observations.canEditRequestConsent}
        onClick={() => rejetOrApproveRequestConsent('reject')}
        className='flex-grow-0 disabled:cursor-not-allowed flex-shrink-0 text-sm font-semibold text-center text-[#e83b25]'
      >
        Deny
      </button>
      <button
        type='button'
        disabled={isLoading || !observations.canEditRequestConsent}
        onClick={() => rejetOrApproveRequestConsent('grant')}
        className='flex-grow-0 flex-shrink-0 text-sm font-semibold text-center disabled:cursor-not-allowed text-primary'
      >
        Approve
      </button>
    </div>
  );
};

function convertDateTimeAccordingToObserverTimeZone(
  sections: any,
  timeZone: string | undefined
) {
  sections.forEach((panel: any) => {
    if (panel.elements) {
      panel.elements.forEach((question: any) => {
        if (
          timeZone !== undefined &&
          question.isUpdated &&
          isFieldIsDateDateTimeOrTime(question.inputType)
        ) {
          const currentTimeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;

          const formatedDateTime = moment
            .tz(question.answer, currentTimeZone)
            .tz(timeZone)
            .format('YYYY-MM-DD HH:mm:ss.SSSS');

          question.answer = formatedDateTime;
        }
      });
    } else if (panel.templateElements) {
      panel.templateElements.forEach((question: any) => {
        if (
          timeZone !== undefined &&
          question.isUpdated &&
          isFieldIsDateDateTimeOrTime(question.inputType)
        ) {
          const currentTimeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;

          const formatedDateTime = moment
            .tz(question.answer, currentTimeZone)
            .tz(timeZone)
            .format('YYYY-MM-DD HH:mm:ss.SSSS');
          question.answer = formatedDateTime;
        }
      });
    }
  });

  return sections;
}

function isFieldIsDateDateTimeOrTime(type: string): boolean {
  return ['date', 'datetime-local', 'time'].includes(type);
}

const getPanelElements = (panel: any) => {
  const panelElements = panel.templateElements?.length
    ? panel.templateElements
    : panel.elements || [];
  return panelElements;
};

const isDynamicPanel = (panel: any) => {
  const panels = Object.values(panelsName);
  const isDynamicPanel =
    panel.type === 'paneldynamic' && !panels.includes(panel.name);
  return isDynamicPanel;
};
