import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "";

export const useInitializeMap = (mapContainer: any) => {
  const [map, setMap] = useState<any>(null);
  const [isMapFullyLoaded, setIsMapFullyLoaded] = useState(false);

  useEffect(() => {
    if (map || !mapContainer.current) return;

    const initializeMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [-145.6433003, 65.0710178],
      zoom: 2,
      renderWorldCopies: false,
      minZoom: 0.999,
      // @ts-ignore
      projection: {
        name: "mercator",
      },
    });

    initializeMap.on("load", () => setIsMapFullyLoaded(true));
    const nav = new mapboxgl.NavigationControl();
    initializeMap.addControl(nav, "bottom-right");

    // adding full screen control
    initializeMap.addControl(new mapboxgl.FullscreenControl());

    initializeMap.on("fullscreenchange", () => {
      console.log("event");
    });

    setMap(initializeMap);
    // adding zoom and navigation controls

    return () => {
      setIsMapFullyLoaded(false);
    };
  }, []);

  return { map, isMapFullyLoaded };
};
