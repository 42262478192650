import React, { useEffect, useState } from "react";
/***** Components *****/
import FormInput from "../Components/Inputs/formInput";
import FormHeader from "../Components/FormHeader";
import Button from "view/components/Button";
import AuthLayout from "../Components/AuthLayout";
import { Toasts } from "view/components/Toasts";
/***** API services *****/
import apiLibrary from "services/api";
/***** External tools *****/
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
/***** Assets *****/
import LeftSealImg from "assets/images/Auth/Seal2.png";
import RightSealImg from "assets/images/Auth/Seal1.png";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { useDispatch } from "react-redux";
import Head from "view/components/Head";

interface ForgetPasswordPageImage {
  value: null | string; // Assuming the value can be a string or null
  description: string;
  key: string;
  fileId: number;
  filePath: string;
}
export const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [forgetPasswordPageImage, setForgetPasswordFormImage] =
    useState<ForgetPasswordPageImage>({
      value: null,
      description: "",
      key: "",
      fileId: 0,
      filePath: "",
    });
  const [isLoading, setLoading] = useState(false);
  // Initializing values for the form fields
  const initialValues = {
    emailOrPhone: "",
  };

  /**
   * Handles the submission of the forgot password form.
   *
   * @param {Object} values - Form values containing user's email or phone.
   * @param {Object} options - Options object containing setSubmitting function.
   * @param {Function} options.setSubmitting - Function to set the submitting state.
   */

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      dispatch(setLoadingProgress(getRandomProgress()));
      const res = await apiLibrary.Auth.forgotPassword(values.emailOrPhone);
      Toasts.success(res.message);
      navigate("/auth/signIn");
    } catch (error: any) {
      // Handling the error appropriately, such as displaying an error message
      const errorMsg = error?.response?.data?.message ?? error.message;
      //  console.log(errorMsg,'dsfjsdfkldlfkjskdlflks');
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };
  // Form validation schema using Yup
  const validationSchema = Yup.object().shape({
    emailOrPhone: Yup.string()
      .matches(/^\S*$/, "No white spaces are allowed")
      .matches(
        /^(?:(?:\+|0{0,2})[1-9]\d{0,3}[-\s]?)?(?:\d{1,4}[-\s]?){1,5}(?:[xX]{1,2}[ ]?\d{1,4})?$|^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/,
        "Invalid email or phone number"
      )
      .required("Email Or Phone Number Is Required"),
  });

  const getForgetPasswordFormImage = async () => {
    setLoading(true);
    try {
      const { data } = await apiLibrary.stystemSettings.getUniversalImages(
        "forget_password_page_image"
      );

      setForgetPasswordFormImage(data.forgetPasswordPageImage);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getForgetPasswordFormImage();
  }, []);
  return (
    <div className="cursor-default">
      <AuthLayout backgroundImage={forgetPasswordPageImage?.filePath}>
        <Head title="Forgot Password" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }: any) => (
            <Form className="h-full">
              <div className="w-[370px] xl:w-[509px] px-8 xl:px-12">
                <FormHeader />
                <div className="flex flex-col gap-4">
                  <p className="text-xl text-secondaryMid font-semibold dark:text-textMain">
                    Password Recovery
                  </p>
                  {/* inputForm */}
                  <FormInput
                    disabled={isSubmitting}
                    label="Phone Number Or Email"
                    type="text"
                    name="emailOrPhone"
                    id="EmailOrPhone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Phone Number Or Email"
                    error={errors.emailOrPhone}
                    touched={touched.emailOrPhone}
                  />
                  {/* Submit Button */}
                  <Button
                    type="submit"
                    text="Next"
                    filledColor="primary"
                    textColor="textWhite"
                    className="p-2 px-5 hover:bg-primaryMid"
                    width="full"
                    height="10"
                    fontStyle="font-semibold"
                    disabled={isSubmitting}
                  />
                  {/*Using Signin Component*/}
                  <SignInLink />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </AuthLayout>
    </div>
  );
};

// Component for SigninLink
const SignInLink = () => {
  return (
    <div className="flex items-center gap-1.5">
      <p className="pt-1.5 text-sm font-Overpass text-secondaryMid dark:text-caption ">
        I remembered the Password
      </p>
      <div>
        <Link
          to="/auth/signIn"
          className="text-sm font-semibold text-primary hover:text-primaryMid dark:text-textLink dark:hover:text-primaryMid"
        >
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default ForgetPassword;
