import { CreateRegionLayer } from "./CreateRegionLayer";

const Sidebar = ({ dataSourcesData }: { dataSourcesData: any }) => {

  console.log("dataSourcesData 2", dataSourcesData)
  return (
    <section className="basis-1/4 md:min-w-[270px] xl:min-w-[430px] 2xl:min-w-[440px] bg-bgWhite dark:bg-secondaryLight font-Overpass px-6 py-4 relative overflow-hidden h-[84vh]">
      <CreateRegionLayer dataSourcesData={dataSourcesData} />
    </section>
  );
};

export default Sidebar;
