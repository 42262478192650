import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeEditCommunityModalAction } from "store/modals/reducer.actions";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { useNavigate } from "react-router-dom";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import CameraIcon from "assets/icons/HeroIcons/CameraIcon";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import coverImgPlaceholder from "assets/images/Placeholders/cover_img_placeholder.png";
import profileImgPlaceholder from "assets/images/Placeholders/avatar_communityProfile_placeholder.png";
import { MAXIMUM_FILE_SIZE } from "constants/FileSize";
import { fetchCommunityProfileAction } from "store/communityProfile/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import GooglePlacesAutocomplete from "Components/AddressInput";
import { fetchCommunityGeographies } from "store/communityGeography";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

interface EditCommunityModalProps {}

export const EditCommunityModal: React.FC<EditCommunityModalProps> = ({}) => {
  const navigate = useNavigate();
  const { editCommunityModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { communities } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  // Store the validation schema for each step separately

  const currentValidationSchema = Yup.object().shape({
    address: Yup.string().nullable(),
    backgroundImageId: Yup.number().nullable(),
    location: Yup.object().shape({
      lat: Yup.string().nullable(),
      long: Yup.string().nullable(),
    }),
    missionStatement: Yup.string().nullable().max(6000, "6000 Max Characters"),
    name: Yup.string().required("Name is required"),
    profileImageId: Yup.string().nullable(),
    type: Yup.string().nullable(),
  });

  const handleClose = () => {
    setProfileImageUrl("");
    setCoverImageUrl("");
    dispatch(closeEditCommunityModalAction());
  };

  async function _submitForm(values: any, actions: any) {
    try {
      const {
        backgroundImageId,
        profileImageId,
        name,
        address,
        location,
        missionStatement,
        type,
      } = values;
      const res = await apiLibrary.Communities.editCommunity(
        editCommunityModal.data.communityId,
        {
          backgroundImageId,
          profileImageId,
          name,
          address,
          location,
          missionStatement,
          type: type.toLowerCase(),
        }
      );
      dispatch(
        fetchCommunityProfileAction(editCommunityModal.data.communityId)
      );
      if (communities.canViewRegionsCommunities) {
        dispatch(
          fetchCommunityGeographies(editCommunityModal.data.communityId)
        );
      }
      Toasts.success(res.message);
      handleClose();
    } catch (error: any) {
      // Handle any errors that occur during the API call
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("Error creating community:", error);
      // You can also set form errors if needed
      // actions.setErrors({ apiError: "Failed to create community" });
    } finally {
    }
  }
  useEffect(() => {
    setProfileImageUrl(editCommunityModal?.data?.profileImage);

    setCoverImageUrl(editCommunityModal?.data?.backgroundImage);
  }, [editCommunityModal.data]);
  const [isLoading, setIsLoading] = useState({
    profileImage: false,
    coverImage: false,
  });
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const coverImageRef = useRef<HTMLInputElement>(null);
  const profileImageRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: "profile" | "cover",
    setFieldValue: any
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      try {
        const fileSize = file.size / 1024 / 1024; // convert bytes to mb
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error("File size must be less than 10 mb!");
        }

        setIsLoading((prevLoading) => ({
          ...prevLoading,
          [`${imageType}Image`]: true,
        }));

        const uploadResponse = await apiLibrary.file.fileUpload(file);

        const objectUrl = URL.createObjectURL(file);
        if (imageType === "cover") {
          setCoverImageUrl(objectUrl);

          setFieldValue("backgroundImageId", uploadResponse.data.id);
        } else {
          setProfileImageUrl(objectUrl);

          setFieldValue("profileImageId", uploadResponse.data.id);
        }
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errMsg);
      } finally {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          profileImage: false,
          coverImage: false,
        }));
      }
    }
  };

  const handleClick = (imageType: "profile" | "cover") => {
    const inputRef = imageType === "cover" ? coverImageRef : profileImageRef;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Modal
      open={editCommunityModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={editCommunityModal.data}
          validationSchema={currentValidationSchema}
          onSubmit={_submitForm}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <div className="w-[100%] rounded">
                <div className="relative flex flex-col items-start justify-start gap-4 p-6 rounded-lg bg-bgWhite dark:bg-secondaryLight">
                  <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                        Edit Community
                      </p>
                    </div>
                    <button onClick={handleClose} type="button">
                      <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
                    </button>
                  </div>
                  <div className="w-full overflow-y-auto px-1 pb-4 max-h-[70vh]">
                    <div className="relative flex gap-4">
                      <div className="relative w-1/4 h-28">
                        <div className="absolute top-0 left-0 rounded-full w-28 h-28">
                          {isLoading.profileImage && (
                            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50">
                              <div className="inline-flex items-center">
                                <svg
                                  className="w-12 h-12 mr-2 animate-spin text-primaryExtraLight"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          )}
                          <input
                            disabled={isLoading.profileImage}
                            type="file"
                            name="profile"
                            className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
                            multiple={false}
                            ref={profileImageRef}
                            accept=".jpg, .jpeg, .png"
                            onChange={(event) =>
                              handleImageUpload(event, "profile", setFieldValue)
                            }
                          />
                          <img
                            className="absolute left-[-1px] top-[-1px] w-full h-full rounded-full"
                            src={
                              profileImageUrl
                                ? profileImageUrl
                                : profileImgPlaceholder
                            }
                          />
                        </div>
                        <button
                          disabled={isLoading.profileImage}
                          type="button"
                          onClick={() => handleClick("profile")}
                          className="flex justify-center items-center absolute left-[78px] top-[78px] gap-2 p-0.5 w-8 h-8 rounded-3xl bg-bgWhite dark:bg-secondaryLight"
                        >
                          <CameraIcon className="w-5 h-5" />
                        </button>
                      </div>
                      <div className="flex flex-col justify-start items-start relative gap-1.5 w-3/4">
                        <div className="w-full h-[120px] overflow-hidden">
                          {isLoading.coverImage && (
                            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50">
                              <div className="inline-flex items-center">
                                <svg
                                  className="w-12 h-12 mr-2 animate-spin text-primaryExtraLight"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          )}
                          <input
                            disabled={isLoading.coverImage}
                            type="file"
                            name="cover"
                            className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
                            multiple={false}
                            ref={coverImageRef}
                            accept=".jpg, .jpeg, .png"
                            onChange={(event) =>
                              handleImageUpload(event, "cover", setFieldValue)
                            }
                          />
                          <img
                            src={
                              coverImageUrl
                                ? coverImageUrl
                                : coverImgPlaceholder
                            }
                            className="w-full h-full"
                          />
                          <button
                            type="button"
                            disabled={isLoading.coverImage}
                            onClick={() => handleClick("cover")}
                            className="flex justify-center items-center absolute top-0 gap-2 p-0.5 right-1 rounded-3xl w-9 h-9 bg-bgWhite dark:bg-secondaryLight"
                          >
                            <CameraIcon className="relative flex-grow-0 flex-shrink-0 w-6 h-6" />
                          </button>
                        </div>

                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
                          <p className="self-stretch flex-grow-0 flex-shrink-0 w-full text-sm text-left text-textMidLight dark:text-textMain ">
                            Visit{" "}
                            <a
                              className="font-medium text-primary"
                              href="https://www.iloveimg.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.iloveimg.com
                            </a>{" "}
                            to crop your image. For the best result on all
                            devices, use a file of{" "}
                            <span className="font-medium">10 MB</span> or less.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="w-full overflow-y-auto px-1 pb-4 max-h-[70vh]"> */}
                    <div className="flex flex-col w-full gap-2">
                      <div className="w-full">
                        <TextInput
                          label="Community Name*"
                          type="text"
                          placeholder="Community Name"
                          name="name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.name}
                          error={errors?.name}
                          touched={touched.name}
                        />
                      </div>
                      <div className="w-full">
                        <GooglePlacesAutocomplete
                          label="Marker Location*"
                          error={errors?.address}
                          onBlur={handleBlur}
                          placeholder="Geocode"
                          location={values.location}
                          hanndleAddressSelect={(address: any) => {
                            setFieldValue("address", address);
                          }}
                          handleLatitudeAndLongitude={(
                            longitude: any,
                            latitude: any
                          ) => {
                            setFieldValue("location.lat", latitude);
                            setFieldValue("location.long", longitude);
                          }}
                        />
                      </div>
                      <div className="flex w-full space-x-5">
                        <div className="w-1/2">
                          <TextInput
                            label="Marker Label*"
                            type="text"
                            placeholder="Marker Label"
                            name="address"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.address}
                            error={errors?.address}
                            touched={touched.address}
                          />
                        </div>
                        <div className="w-1/2">
                          <TextInput
                            fieldAs="select"
                            label="Type"
                            type="select"
                            name="type"
                            data={["Open", "Private"]}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.type}
                            error={errors?.type}
                            touched={touched.type}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="w-full">
                          <TextInput
                            label="Description"
                            type="text"
                            fieldAs="textarea"
                            placeholder="Add your description here..."
                            name="missionStatement"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.missionStatement}
                            error={errors?.missionStatement}
                            touched={touched.missionStatement}
                            helperText={"6000 Max Characters"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-2 pb-4 pr-8 dark:bg-secondaryLight">
                  <Button
                    type="button"
                    text="Cancel"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={handleClose}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    text="Save"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
