// Components
import Button from "view/components/Button";
import { Toasts } from "view/components/Toasts";

// Store utils
import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
// Third party services
import Dropzone from "react-dropzone";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import handleShapeAndKMLTypeFiles from "Components/Geography/SideBar/utils/handleShapeAndKMLTypeFiles";

import usePermissions from "hooks/usePermissions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import structureFormJSON from "../../../../SurveyJs/utils/structureFormJSON";
import apiLibrary from "services/api";
import { dynamicFormsStatusAction } from "store/filters";
import { getSelectedShapesAndDefaultLayers } from "..";

// Schema

const validationSchema = Yup.object().shape({
  geojson: Yup.mixed().required("GeoJSON is required"),
});

interface UploadFormInterface {
  height: string;
  dataSourcesData: any;
  setIsUploadFormEditDataApplied: Dispatch<SetStateAction<boolean>>;
  isUploadFormEditDataApplied: boolean;
}

const UploadForm = ({
  height,
  setIsUploadFormEditDataApplied,
  dataSourcesData,
  isUploadFormEditDataApplied,
}: UploadFormInterface) => {
  const dispatch = useDispatch();
  const { communityId } = useParams();
  const initialValues = {
    geojson: null,
  };
  const { formJson } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );
  const surveyState = useSelector((state: RootState) => state.surveyJS);
  const { formDetails } = useSelector(
    (state: RootState) => state.formSurveyJsDetails
  );
  const { communities, dynamicForms } = usePermissions();

  const navigate = useNavigate();

  // handlers
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }
    try {
      const mapData =
        values.geojson.geojson ??
        (await handleShapeAndKMLTypeFiles(values.geojson));
      const {
        shapePickers,
        pointPickers,
        drawPolygons,
        dropPins,
        getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      } = surveyState;

      const { json, panelJson, status } = formJson;

      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      const payload: any = {
        form_fields: structureFormJSON(
          json,
          panelJson,
          dataStoredInGlobalState,
          "request",
          dataSourcesData
        ),
        form_area: mapData,
        form_area_mode: "upload",
        form_area_file_name: values.geojson.name,
        status: status,
      };

      // const {selectedDefaultLayers,selectedShapes} = getSelectedShapesAndDefaultLayers(payload.form_fields);

      // payload.selected_layers_ids = selectedDefaultLayers;
      // payload.selected_options = selectedShapes;

      const res = await apiLibrary.Forms.updateFormFields(
        formDetails.id,
        payload
      );

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status ?? ""));
      navigate("/forms/list");
    } catch (error: any) {
      if (error.code === "CRS_MISSING") {
        setFieldError(`geojson`, error.detail);
      } else {
        const errorMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errorMsg);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitFormWithoutFormArea = async (
    setSubmitting: Dispatch<SetStateAction<boolean>> | any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }
    setSubmitting(true);
    try {
      const {
        shapePickers,
        pointPickers,
        drawPolygons,
        dropPins,
        getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      } = surveyState;

      const { json, panelJson, status } = formJson;

      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      const payload: any = {
        form_fields: structureFormJSON(
          json,
          panelJson,
          dataStoredInGlobalState,
          "request",
          dataSourcesData
        ),
        status,
      };

      // const {selectedDefaultLayers,selectedShapes} = getSelectedShapesAndDefaultLayers(payload.form_fields);

      // payload.selected_layers_ids = selectedDefaultLayers;
      // payload.selected_options = selectedShapes;
      const res = await apiLibrary.Forms.updateFormFields(
        formDetails.id,
        payload
      );

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status ?? ""));
      navigate("/forms/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          setFieldError,
          setSubmitting,
        }) => {
          useEffect(() => {
            if (
              formDetails.formArea &&
              !isUploadFormEditDataApplied &&
              formDetails.formAreaMode === "upload"
            ) {
              setFieldValue("geojson", {
                geojson: formDetails.formArea,
                name: formDetails.formAreaFileName,
              });
              setIsUploadFormEditDataApplied(true);
            }
          }, []);

          return (
            <Form>
              <div
                className="pb-12"
                style={{
                  maxHeight: "60vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="pt-3 overflow-y-auto">
                  <FilesDragAndDrop
                    push={(data: any) => {
                      setFieldValue("geojson", data.file);
                    }}
                    remove={() => {
                      setFieldValue("geojson", null);
                    }}
                  />
                  {values.geojson && (
                    <div className="">
                      <div className="pb-1">
                        <div className="relative flex w-full">
                          <div className="flex flex-col items-start justify-start w-[93%] ">
                            <FileUpload
                              handleOnFileUpload={(files: any) => {
                                setFieldValue(`geojson`, files[0]);
                              }}
                              error={errors?.geojson}
                              touched={errors?.geojson}
                              values={values.geojson}
                            />
                          </div>
                          <button
                            type="button"
                            className="absolute right-0 flex items-center justify-center flex-grow-0 flex-shrink-0 top-4 rounded-3xl"
                            onClick={() => {
                              setFieldValue("geojson", null);
                            }}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {errors.geojson && (
                    <p className="flex-grow pt-1 text-xs text-left text-accent_1Dark">
                      Please upload a geojson file
                    </p>
                  )}
                </div>
                <div className="flex justify-between gap-2 pt-2 pb-2 absolute  bottom-[-15px] w-full p-4 bg-bgWhite">
                  <Button
                    type="button"
                    text="Skip"
                    disabled={
                      isSubmitting ||
                      formJson.status === "published" ||
                      !dynamicForms.canEditDynamicFormFields
                    }
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 w-[48.5%]"
                    width="[48.t%]"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={() =>
                      handleSubmitFormWithoutFormArea(setSubmitting)
                    }
                  />
                  <Button
                    type="submit"
                    text="Save"
                    disabled={
                      isSubmitting || !dynamicForms.canEditDynamicFormFields
                    }
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2 w-[48.5%]"
                    width="[48.5%]"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { UploadForm };

const FileUpload = ({ handleOnFileUpload, error, touched, values }: any) => {
  return (
    <div className="w-full py-2">
      <Dropzone
        onDrop={handleOnFileUpload}
        multiple={false}
        accept={ACCEPTED_FILES_TYPES}
      >
        {({ getRootProps, getInputProps }) => {
          const fileName = values
            ? values.name
            : "Upload GeoJson, Shape or Zipped Shapefiles, or KML File ";

          return (
            <div className="pb-2">
              <div
                {...getRootProps()}
                className={`border ${
                  error && touched
                    ? "border-accent_1Dark"
                    : "border-lineDark dark:border-lineLight"
                } px-3 py-2 rounded flex items-center justify-between`}
              >
                <input {...getInputProps()} />
                <p className="text-[17px] text-textLightExtra overflow-x-auto whitespace-nowrap mr-2 dark:text-textMain">
                  {fileName}
                </p>
                <CloudUploadIcon />
              </div>
            </div>
          );
        }}
      </Dropzone>
      {touched && error && (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {error}
        </p>
      )}
    </div>
  );
};

const ACCEPTED_FILES_TYPES = {
  "application/geo+json": [".geojson"],
  "application/vnd.google-earth.kml+xml": [".kml"],
  "application/octet-stream": [".shp"],
  "application/zip": [".zip"],
};

const FilesDragAndDrop = ({ push, remove }: any) => {
  const handleOnDropFiles = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];
      push({
        name: file.name,
        file,
      });
    },
    [push]
  );

  return (
    <Dropzone onDrop={handleOnDropFiles} accept={ACCEPTED_FILES_TYPES}>
      {({ getRootProps, getInputProps }) => (
        <>
          <div
            {...getRootProps({ className: "dropzone" })}
            className="flex flex-col items-center self-stretch justify-center flex-grow-0 flex-shrink-0 px-4 py-2 bg-white border border-dashed rounded-lg hover:cursor-pointer border-lineDark"
          >
            <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              <input {...getInputProps()} />
              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg ">
                <CloudUploadIcon />
              </div>
              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                <div className="flex justify-center items-center flex-grow  px-1 pt-1.5 pb-2">
                  <p className="flex-grow w-[368px] text-sm text-center text-textMid dark:text-textMain">
                    Drag and drop your GeoJSON , Shape Or Kml files here or
                    click in this area
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Dropzone>
  );
};
