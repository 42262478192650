export interface IProfileObservation {
  code: string;
  community: {
    communityId: number;
    name: string;
    profileImage: {
      original: string;
      thumb: string;
    };
  };
  dynamicForm: {
    id: number;
    programId: number;
    name: string;
    protocol: {
      id: number;
      title: string;
    };
    profileImage: {
      original: string;
      thumb: string;
    };
  };
  program: {
    id: number;
    name: string;
    profileImage: {
      original: string;
      thumb: string;
    };
    programStatus: string | undefined;
  };
  createdBy: {
    name: string;
  };
  imported?: boolean | null;
  accessUntil: string;
  edited: {
    date: string;
  };
  flag: string;
  id: number;
  isLoading: boolean;
  observer: {
    id: number;
    name: string;
    profileImage: {
      original: string;
      thumb: string;
    };
  };
  reviewedBy: {
    id: number;
    name: string;
    profileImage: {
      original: string;
      thumb: string;
    };
  };
  externalReviewer: {
    name: string;
    profileImage: {
      original: string;
      thumb: string;
    };
  };
  assignedBy: {
    id: number;
    name: string;
    profileImage: {
      original: string;
      thumb: string;
    };
  };
  reasonToEdit: string;
  sections: any[];
  status: string;
  requireQa: boolean;
  editRequestAt: string;
  externalReviewerAccessUntil: string;
  observerTimezone: string;
  switchToObserverTimeZone: boolean;
  submissionLocalDateTime: string;
  collapseAccordion: boolean;
  isAnonymous: boolean
}

export const initialState: IProfileObservation = {
  code: "",
  dynamicForm: {
    id: 0,
    programId: 0,
    name: "",
    protocol: {
      id: 0,
      title: "",
    },
    profileImage: {
      original: "",
      thumb: "",
    },
  },
  program: {
    id: 0,
    name: "",
    profileImage: {
      original: "",
      thumb: "",
    },
    programStatus: "",
  },
  community: {
    communityId: 0,
    name: "",
    profileImage: {
      original: "",
      thumb: "",
    },
  },
  assignedBy: {
    id: 0,
    name: "",
    profileImage: {
      original: "",
      thumb: "",
    },
  },
  createdBy: {
    name: "",
  },
  imported: false,
  accessUntil: "",
  edited: {
    date: "",
  },
  flag: "",
  id: 0,
  isLoading: false,
  observer: {
    id: 0,
    name: "",
    profileImage: {
      original: "",
      thumb: "",
    },
  },
  reviewedBy: {
    id: 0,
    name: "",
    profileImage: {
      original: "",
      thumb: "",
    },
  },
  externalReviewer: {
    name: "",
    profileImage: {
      original: "",
      thumb: "",
    },
  },
  sections: [],
  status: "",
  reasonToEdit: "",
  editRequestAt: "",
  externalReviewerAccessUntil: "",
  requireQa: false,
  observerTimezone: "",
  switchToObserverTimeZone: false,
  collapseAccordion: false,
  isAnonymous: false,
  submissionLocalDateTime: ""
};
