import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import { Filters, Header, Tabs, Pagination, Table } from "Components/Reports";
// Hooks
import { ReportsTable } from "hooks/useTable";

// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { TableProps } from "Components/Programs/Table/types";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchReports } from "store/reports/initialState";
import { fetchReportsAction } from "store/reports";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { reportsResetFiltersAction, reportsStatusAction } from "store/filters";
import { useParams } from "react-router-dom";
import usePermissions from "hooks/usePermissions";
import useRoles from "hooks/roles";
import { ReportAccessPlaceholder } from "./ReportAccessPlaceholder";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";
import { setReportsAnalyticsAction, setReportsFiltersAction } from "store/filters/reducer.actions";

export const Reports = () => {
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const { organizationId } = useParams();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data } = useSelector<RootState, IfetchReports>(
    (state) => state?.allReports
  );
  const { reportsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  useLayoutEffect(() => {
    dispatch(setReportsFiltersAction());
  }, []);

  // const { reports } = usePermissions();
  const { isExternalDataEndReviewer } = useRoles();

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = ReportsTable(data?.reports);

  useEffect(() => {
    dispatch(fetchReportsAction());
    saveFiltersDataToLocalStorage(reportsFilter);
  }, [reportsFilter]);

  useEffect(() => {
    if (isExternalDataEndReviewer()) {
      dispatch(reportsStatusAction("all"));
    }
    return () => {
      dispatch(setReportsAnalyticsAction());
    };
  }, []);
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          {!isExternalDataEndReviewer() && (
            <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
                <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                  <Tabs />
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
              />
            </div>
          </div>
        </div>
        {data?.reports?.length < 1 && isExternalDataEndReviewer() ? (
          <ReportAccessPlaceholder />
        ) : (
          <RenderView
            flexRender={flexRender}
            table={table}
            columnOrder={columnOrder}
            columns={columnsConfig}
            columnVisibility={columnVisibility}
            setSorting={setSorting}
            sorting={sorting}
          />
        )}
      </div>
    </div>
  );
};
export default Reports;

const RenderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { data, stateIs } = useSelector<RootState, IfetchReports>(
    (state) => state?.allReports
  );

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
      {stateIs == "Idle" && data?.reports?.length === 0 ? (
        <NoRecordFound />
      ) : stateIs === "Pending" ? (
        <div className="loader min-h-[45vh] justify-center items-center flex w-full">
          <TailSpin
            height="50"
            width="50"
            color="#005C89"
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Table
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columns}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      )}
      <Pagination />
    </div>
  );
};
